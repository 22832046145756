/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
import ContentfulElement from '../../ich/contentful-element';

const element = props => {
  const { anchor, contentEntity } = props;
  return (
    <section id={anchor}>
      <a name={anchor} />
        <CMSWrapper {...contentEntity}>
          <div className="container pt-150 pb-150">
            <div className="section-title text-center">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  {contentEntity && contentEntity && contentEntity.widgets.map(item => (
                          <ContentfulElement key={item.id} {...item} />
                  ))}
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-md-6">
                  <CMSWrapper {...contentEntity}>
                    { contentEntity && contentEntity.featureImage ? <img className="img-fullwidth mt-80" src={contentEntity.featureImage.size({ width: 500, height: 445, focus: 'face', fit: 'fill'  })} alt="" /> : null }
                  </CMSWrapper>
                </div>
                <div className="col-md-6">
                  <div className="events-venue border-theme-colored border-5px p-40 pt-10 pb-20">
                    {contentEntity
                      && contentEntity
                      && contentEntity.otherRows[0]
                      && contentEntity.otherRows[0].entities
                      && contentEntity.otherRows[0].entities.map(item => (
                        <ContentfulElement key={item.id} {...item} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CMSWrapper>
    </section> 
  );
}

export default element;