/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'gatsby';

import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

const renderComponent = ({ contentEntity, placeholder }) => (
  <div className="col-sm-6 col-md-4 mb-30">
    <CMSWrapper {...contentEntity}>
    <div className="class-items bg-white border-1px p-20">
      <div className="thumb">
      <img className="img-fullwidth" src={
          contentEntity.image ? contentEntity.image.size({
            width: 317,
            height: 317,
            focus: 'face'
          })
          : placeholder ? placeholder.size({
              width: 317,
              height: 317,
              focus: 'face'
            }) : "http://placehold.it/317x317"
        } alt={contentEntity.image ? contentEntity.image.description : "no image"} />
        <div className="viewtime">
          <Link to={`/location/${contentEntity.slug}`} className="btn btn-theme-colored btn-flat mt-10"> View Details</Link>
        </div>
      </div>
      <h3 className="">
        <Link to={`/location/${contentEntity.slug}`} style={{minHeight: '70px', display: 'block'}}><span className="text-theme-colored">{contentEntity.region.reduce((a,b) => `${a} ${b}`)}</span> - {contentEntity.title}</Link>
      </h3>
    </div>
    </CMSWrapper>
  </div>
);

export default renderComponent;