/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import {Link} from 'gatsby';
import classnames from 'classnames'
// import ContentfulElement from '../../ich/contentful-element';
import CMSWrapper, { ImageEditor, TypeEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
//import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

/* const titleDiv = style.h4`

`; */

const innerComponent = (props) => {
  const { bgImage,  placeholder, all } = props;
  const headerClassName = classnames([
    "title",
    {
      "text-white": bgImage
    }
  ]);
  const subHeaderClassName = classnames([
    {
      "text-white": bgImage
    }
  ]);
  return (
    <div className="container pt-150 pb-150">
      <div className="section-title text-center">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h2 className={headerClassName}>News, Events &amp; <span className="text-theme-colored">blog</span></h2>
            <p className={subHeaderClassName}>Whats happening in the club.</p>
          </div>
        </div>
      </div>
      <div className="section-content">
        <div className="row">
          <div className="col-md-12 mb-sm-20">
            <div className="owl-carousel-4col">
              {all.map(item => {
                switch(item.type) {
                  case 'news':
                  case 'event':
                  case 'blog':
                  default:
                    return (
                      <div key={item.id} className="item">
                        <article className="post clearfix maxwidth600 mb-sm-30">
                          <CMSWrapper {...item}>
                          <div className="entry-content bg-white border-1px p-20">
                            <h4 className="entry-title mt-0 pt-0" style={{height: '110px'}}>
                              
                                <Link to={`/${item.type}/${item.slug}`}><span className="text-theme-colored text-uppercase">{item.type}</span> - {item.title}</Link>
                              
                            </h4>
                            <div className="entry-date"> 
                              {/* <i className="fa fa-thumbs-o-up mr-5"></i> 245
                              <i className="fa fa-comments-o ml-5 mr-5"></i> 5 comments */}
                              <i className="fa fa-calendar ml-5 mr-5" /> {new Date(item.date || item.createdAt).toLocaleDateString()}
                            </div>
                            <div className="thumb mt-20">
                              <img src={item.image ? item.image.size({width:360, height: 246}) : placeholder.size({width:360, height: 246})} alt="" />
                            </div>
                            <p className="text-left mb-20 mt-15" dangerouslySetInnerHTML={{__html: item.summary}} />
                            <Link className="btn btn-sm btn-theme-colored" to={`/${item.type}/${item.slug}`}>Read more</Link>
                            <div className="clearfix" />
                          </div>
                          </CMSWrapper>
                        </article>
                      </div>
                    );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const element = props => {
  const { anchor = 'news-events-blogs', bgImage,  news, events, blogs } = props;
  const all = [].concat([], news, events, blogs).sort((a, b) => (a.date || a.createdAt) < (b.date || b.createdAt)).filter(x => x);
  if(all.length) {
    return bgImage ? 
      (
        <section id={anchor} className="divider parallax layer-overlay overlay-dark-6" data-parallax-ratio="0.1" data-bg-img={(bgImage).size({width: 2372, height: 1571})}>
          <TypeEditor type="news" space={(news[0] || events[0] || blogs[0]).space}>
          <TypeEditor type="event" space={(news[0] || events[0] || blogs[0]).space}>
          <TypeEditor type="blog" space={(news[0] || events[0] || blogs[0]).space}>
            <a name={anchor} />
            <ImageEditor {...bgImage}>
              {innerComponent({...props, all})}
            </ImageEditor>
          </TypeEditor>
          </TypeEditor>
          </TypeEditor>
        </section>
      )
      :
      (
        <section id="blog" className="divider">
          <a name={anchor} />
          <ImageEditor {...bgImage}>
          {innerComponent({...props, all})}
          </ImageEditor>
        </section>
      );
  }
  return null;
}

export default element;