import React from 'react';

import CMSWrapper, { ImageEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
import ContentfulElement from '../../ich/contentful-element';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

const element = props => {
  const { anchor, bgImage, contentEntity } = props;
  return (
    <section id={anchor} className="divider parallax fullscreen" data-parallax-ratio="0.1" data-bg-img={bgImage.size({ width: 2000 })}>
      <ImageEditor {...bgImage}>
        <CMSWrapper {...contentEntity}>
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container pt-150 pb-150">
                <div className="row">
                  <div className="col-md-10 col-md-push-4">
                    <div className="pb-50 pt-30">
                      {contentEntity && contentEntity && contentEntity.elements.map(item => (
                        <ContentfulElement key={item.id} {...item} />
                      ))}
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </CMSWrapper>
      </ImageEditor>
    </section> 
  );
}

export default element;