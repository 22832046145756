import React, { Component } from 'react';
import { TypeEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
import LocationPreview from ".";

class LocationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: []
    }
  }

  componentDidMount() {
    const { locations } = this.props;
    const foundRegions = [];
    locations.forEach(l => l.region.forEach(r => {
    if(foundRegions.indexOf(r) === -1)
      foundRegions.push(r);
    }));
    this.setState({
      regions: foundRegions.map(r => ({
        region: r,
        shown: true,
      }))
    });
  }

  toggleRegion(region) {
    return () => {
      const { regions } = this.state;
      const localRegions = [].concat(regions);
      regions.forEach((r, i) => {
        if(r.region === region.region) {
          localRegions[i].shown = !r.shown;
        }
      });
      this.setState({
        regions: localRegions
      });
    }
  }

  render() {
    const {regions} = this.state;
    const {locations, placeholder} = this.props;
    return (
      <div className="container pt-150 pb-150">
        <TypeEditor type="location" space={locations[0].space}>
        <div className="section-title text-center">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2 className="title text-white">Our <span className="text-theme-colored">Locations</span></h2>
              <p className="text-white">Join our martial art club and be healthy.</p>
              {regions.map(region => region.shown ? 
                  <button key={region.region} type="button" className="btn btn-colored btn-lg btn-theme-colored pl-20 pr-20" onClick={this.toggleRegion(region)}>
                    {region.region}
                  </button>
                : 
                  <button type="button" className="btn btn-colored btn-lg pl-20 pr-20" onClick={this.toggleRegion(region)}>
                    {region.region}
                  </button>
                
              )
              }
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row">
            {locations.filter(l => l.region.reduce((a,c) => 
              a || regions.reduce((b,v) => b || (v.region === c && v.shown), false), false))
              .sort((a,b) => 
                b.region.sort() < a).map(item => (
              <LocationPreview key={item.id} contentEntity={item} placeholder={placeholder} />
            ))}
          </div>
        </div>
        </TypeEditor>
      </div>
    );
  }
};

export default LocationPanel;