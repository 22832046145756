/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import classNames from 'classnames';
import CMSWrapper, { ImageEditor, TypeEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

const element = props => {
  const { anchor = 'gallery', photoGalleries } = props;
  const randomGallery = {
    slug: 'random',
    title: 'Random Selection',
    images: photoGalleries.reduce((a, g) => [].concat(a, g.images), []).sort(() => Math.random() < 0.5)
  };
  if(randomGallery.images.length > 12) {
    randomGallery.images = randomGallery.images.slice(0, 12);
  }
  return (
    <section id={anchor}>
      <a name={anchor} />
          <div className="container-fluid mb-0">
            <div className="section-title text-center">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <h2 className="title">Our <span className="text-theme-colored">gallery</span></h2>
                  <p className="">Fun, Fitness, Challenge</p>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-md-12">
                  <TypeEditor type="photoGallery" space={photoGalleries[0].space}>
                  <div className="portfolio-filter text-center">
                    
                      <a href="#random" className="active" data-filter=".random">Random</a>
                      {photoGalleries.map(g => (
                        <a key={g.id} href={`#${g.slug}`} className="" data-filter={`.${g.slug}`}>{g.title}</a>  
                      ))}
                    
                  </div>
                  </TypeEditor>
                  <div className="gallery-isotope grid-6 gutter-small clearfix" data-lightbox="gallery">
                  
                      {randomGallery.images.map(i => {
                        const glClass = classNames(['gallery-item', randomGallery.slug]);
                        return (
                          <div key={`${randomGallery.slug}-${i.id}`} className={glClass}>
                            <div className="thumb">
                              <img className="img-fullwidth" src={i.size({width: 340, height: 340})} alt={`${randomGallery.slug} - ${i.title}`} />
                              <div className="overlay-shade" />
                              <div className="text-holder">
                                <div className="title text-center">{`${randomGallery.slug} - ${i.title}`}</div>
                              </div>
                              <div className="icons-holder">
                                <div className="icons-holder-inner">
                                  <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored zoom-gallery">
                                    
                                    <a href={i.size({width: 640})} data-lightbox-gallery="gallery" title={`${randomGallery.slug} - ${i.title}`}><i className="fa fa-picture-o" /></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  
                    {photoGalleries.map(g => 
                      g.images.filter(x=>x).map(i => {
                        const glClass = classNames(['gallery-item', g.slug]);
                        return (
                          <div key={`${g.slug}-${i.id}`} className={glClass}>
                            <div className="thumb">
                              <img className="img-fullwidth" src={i.size({width: 340, height: 340})} alt={`${g.slug} - ${i.title}`} />
                              <div className="overlay-shade" />
                              <div className="text-holder">
                                <div className="title text-center">{`${g.slug} - ${i.title}`}</div>
                              </div>
                              <div className="icons-holder">
                              <CMSWrapper {...g}>
                                <ImageEditor {...i}>
                                <div className="icons-holder-inner">
                                  <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored zoom-gallery">

                                      <a href={i.size({width: 640})} data-lightbox-gallery="gallery" title={`${g.slug} - ${i.title}`}><i className="fa fa-picture-o" /></a>
                                    
                                  </div>
                                </div>
                                </ImageEditor>
                              </CMSWrapper>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  );
}

export default element;