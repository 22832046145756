/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable dot-notation */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'jquery';

import styled from 'react-emotion';


import LocationPanel from '../components/Locations/panel';
import { updateData } from '../data/contentful/actions';
import Layout from '../components/layout';

import FullPageLander from '../components/IndexPage/FullPageLander';
import SectionImageRight from '../components/IndexPage/SectionImageRight';
import SectionImageLeft from '../components/IndexPage/SectionImageLeft';
import SectionFitness from '../components/IndexPage/SectionFitness';
import SectionAboutClub from '../components/IndexPage/SectionAboutClub';
import SectionInstructors from '../components/IndexPage/SectionInstructors';
import SectionGallery from '../components/IndexPage/SectionGallery';
import SectionNews from '../components/IndexPage/SectionNews';

const ls = (key) => {
  if (typeof window !== `undefined`) {
    return window ? JSON.parse(window.localStorage.getItem(key)) : false
  }
  return false
}


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.state,
    };
  }

  componentWillMount() {

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData()
    if(ls('showCMSTags')) {
      const reload = () => {
        this.props.updateData()
        setTimeout(reload, 5000);
      };
      setTimeout(reload, 5000);
    }
    
  }

  render() {
    const { content } = this.props;
    const { assets, elements } = content.data;
    const instructors = content.data.instructor.all;
    const news = content.data.news.all;
    const events = content.data.event.all;
    const locations = content.data.location.all;
    const photoGalleries = content.data.photoGallerys.all;
    const placeholder = assets.Placeholder;
    return (
      <Layout runAllMain={typeof window !== 'undefined' && !window.location.href.startsWith('http://localhost')}>
        <FullPageLander bgImage={assets["33uaPBDPz75pfDgCdJBJnO"]} contentEntity={elements['7oPctkhLhZCDhfGNtUhZ8']} />
        <SectionImageRight contentEntity={elements["4cCAVLod84qb1Ac3gcbTud"]} />
        <SectionImageLeft anchor="welcome" contentEntity={elements['3vlZ9GhLIA06xvSQGihrbQ']} />
        <SectionFitness contentEntity={elements['30TSNMQxckjFb9Ys7ySErP']} />
        <SectionAboutClub anchor="about" contentEntity={elements['1c8oyQuIw8M6mxpSwiKHfJ']} />
        <section id="services" className="divider parallax layer-overlay overlay-dark-6" data-parallax-ratio="0.1" data-bg-img={assets["4evn5UQCkSXW3aVjnMirNx"].size({ width: 1500 })}>
          <a name="locations" />
          <LocationPanel
            locations={this.props.content.data.location.all}
            placeholder={this.props.content.data.assets.Placeholder}
          />
        </section>

        <SectionInstructors placeholder={placeholder} instructors={instructors} bgImage={assets["2ph6kUJ675wivmYhTmxfjJ"]}/>
        <SectionGallery photoGalleries={photoGalleries} />
        <SectionNews bgImage={assets['5XRKWq5uu9ij7sYfLZJeAl']} placeholder={placeholder} news={news} events={events} blogs={[]} />
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
