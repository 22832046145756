import React from 'react';
import {Link} from 'gatsby';

import CMSWrapper, { ImageEditor, TypeEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

const element = props => {
  const { placeholder, instructors, bgImage } = props;
  return (
    <section
      id="experts"
      className="divider parallax layer-overlay overlay-dark-4"
      data-parallax-ratio="0.1"
      data-bg-img={bgImage.size({ width: 1500 })}
    >
      <TypeEditor type="instructor" space={(instructors[0]).space}>
      <ImageEditor {...bgImage}>
        <div className="container pt-150 pb-150">
          <div className="section-title text-center">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <h2 className="title text-white">Our <span className="text-theme-colored"> Instructors</span></h2>
                <p className="text-white">Tae = foot; Kwon = fist; Do = art</p>
              </div>
            </div>
          </div>
          
          <div className="section-content">
            <div className="row">
              <div className="col-md-12">
                <div className="owl-carousel-3col">
                    {instructors.map(instructor => (
                      <div key={instructor.id} className="item">
                        <CMSWrapper {...instructor}>
                          <div className="trainer-item style2">
                            <div className="trainer-thumb"> <img src={(instructor.image || placeholder).size({ width: 450, height: 502 })} alt="" className="img-fullwidth img-responsive" /> </div>
                            <div className="trainer-info pb-20">
                            <div className="trainer-biography">
                              <h3 className="text-white">{instructor.name}</h3>
                              <h5 className="text-white">{instructor.grade}</h5>
                            </div>
                            <div className="viewtime">
                              <Link to={`/instructor/${instructor.slug}`} className="btn btn-theme-colored btn-flat mt-10"> View Details</Link>
                            </div>
                            {/* <div className="social-network pt-10">
                              <ul className="list-inline">
                                <li className="rotate"><a href=""><i className="fa fa-facebook bg-theme-colored no-rotate"></i></a></li>
                                <li><a href=""><i className="fa fa-twitter bg-theme-colored"></i></a></li>
                                <li><a href=""><i className="fa fa-dribbble bg-theme-colored"></i></a></li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </CMSWrapper>
                    </div>
                    ))}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </ImageEditor>
      </TypeEditor>
    </section>
  );
}

export default element;