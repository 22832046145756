/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import CMSWrapper, { ImageEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
import ContentfulElement from '../../ich/contentful-element';

const element = props => {
  const { anchor, contentEntity } = props;
  return (
    <section id={anchor} className="divider layer-overlay overlay-dark-6 parallax" data-parallax-ratio="0.1" data-bg-img={contentEntity.bgImage.size({ width: 1500 })}>
      <a name={anchor} />
      <ImageEditor {...contentEntity.bgImage}>
        <CMSWrapper {...contentEntity}>
        
          <div className="container pt-150 pb-150">
            <div className="section-content">
              <div className="row mb-1">
                <div className="col-md-6">
                  {contentEntity && contentEntity && contentEntity.widgets.map(item => (
                        <ContentfulElement key={item.id} {...item} />
                  ))}
                </div>
                <div className="col-md-6">
                  <CMSWrapper {...contentEntity}>
                  <img src={contentEntity.featureImage && contentEntity.featureImage.size({
                    width: 500,
                    height: 445,
                    focus: 'face',
                    fit: 'fill',
                  })} alt={contentEntity.featureImage && contentEntity.featureImage.title} />
                  </CMSWrapper>
                </div>
              </div>
              {contentEntity && contentEntity.otherRows && contentEntity.otherRows.map(row => (
                  <ContentfulElement key={row.id} {...row} />
                )) }
            </div>
          </div>
        </CMSWrapper>
      </ImageEditor>
    </section> 
  );
}

export default element;

/* 
<section id="welcome" className="divider layer-overlay overlay-dark-6 parallax" data-parallax-ratio="0.1" data-bg-img={assets["1IHkA7cCHHKlTYVT4JJcS9"].size({ width: 1500 })}>
          <div className="container pt-150 pb-150">
            <div className="section-content">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="text-white">Fitness, wellbeing, confidence</h5>
                  <h2 className="mt-0 text-white">WHY SHOULD YOU BECOME A MEMBER</h2>
                  <h5 className="text-white">
                    <ul className="list">
                      <li>Learn Rhee Tae Kwon Do in the safe way and have fun!</li>
                      <li>NO training contracts or direct debits</li>
                      <li>Unlimited monthly training for a monthly training fee</li>
                      <li>Numerous DoJangs at high quality safe venues</li>
                      <li>Worldwide recognition of RHEE TKD rank</li>
                    </ul>
                  </h5>
                  <p className="text-white">WHO CAN LEARN RHEE TAE KWON DO?<br />
                    Anyone at all can learn, enjoy and reap the benefits of Rhee Tae Kwon Do, male or female, large or small,
                    strong or weak, young or old... You don{`'`}t even need any prior knowledge of martial arts.</p>
                </div>
                <div className="col-md-6">
                  <img src={assets["1IHkA7cCHHKlTYVT4JJcS9"].size({ width: 500, height: 445 })} alt="" />
                </div>
              </div>
              <div className="row multi-row-clearfix mt-30">
                <div className="col-sm-4 col-md-4">
                  <div className="icon-box mb-60">
                    <a href="#" className="icon icon-circled icon-bordered icon-gray icon-border-effect mb-0 mr-10 pull-left flip">
                      <i className="flaticon-people text-theme-colored"></i>
                    </a>
                    <div className="pt-13">
                      <h4 className="icon-box-title mt-0 mb-0 text-theme-colored">Fitness</h4>
                      <p className="text-white">
                        The movements in Rhee Tae Kwon Do are scientifically designed with a specific purpose in mind. Many
                        movements involve beneficial stretching of the arms, legs and torso and regular practice of the Art will
                        increase your level of aerobic (heart and lung) fitness. With the toning of muscles and lose of weight,
                        members should eventually achieve a figure that is proportionate, strong and graceful. Also, the mental
                        aspects of Rhee Tae Kwon Do enable you to develop a far more serene and peaceful attitude to life in
                        general.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4">
                  <div className="icon-box mb-60 p-0">
                    <a href="#" className="icon icon-circled icon-bordered icon-gray icon-border-effect mb-0 mr-10 pull-left flip">
                      <i className="flaticon-sport text-theme-colored"></i>
                    </a>
                    <div className="pt-13">
                      <h4 className="icon-box-title mt-0 mb-0 text-theme-colored">BENEFITS</h4>
                      <p className="text-white">
                        One of the greatest advantages of Rhee Tae Kwon Do as a martial art is that it is not only a superior Art of
                        self-defence, but it will also enhance every aspect of your mental and physical well-being.
                        The practice of Rhee Tae Kwon Do imparts to each member: self-control, self-discipline, high moral
                        standards, improved psychological outlook and overall strength of character.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4">
                  <div className="icon-box p-0 mb-30">
                    <a href="#" className="icon icon-circled icon-bordered icon-gray icon-border-effect mb-0 mr-10 pull-left flip">
                      <i className="flaticon-sports-7 text-theme-colored"></i>
                    </a>
                    <div className="pt-13">
                      <h4 className="icon-box-title mt-0 mb-0 text-theme-colored">TRADITIONAL &amp; NON-COMPETITIVE</h4>
                      <p className="text-white">
                        Unlike some martial arts, Rhee Tae Kwon Do is a traditional and non-competitive art. The emphasis is on
                        building your individual physical and mental confidences through the study of the art. Rhee Tae Kwon Do is
                        regarded as one of the most comprehensive, dynamic and effective arts of self defence in this day and age.
                        You will no only learn more about your whole self but also feel the real confidence of being able to defend
                        yourself and your family.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
*/