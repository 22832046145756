/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import CMSWrapper, { ImageEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
import ContentfulElement from '../../ich/contentful-element';

const element = props => {
  const { anchor, contentEntity } = props;
  return (
    <section id={anchor} className="divider layer-overlay overlay-dark-6 parallax" data-parallax-ratio="0.1" data-bg-img={contentEntity.bgImage.size({ width: 1500 })}>
      <a name={anchor} />
      <ImageEditor {...contentEntity.bgImage}>
        <CMSWrapper {...contentEntity}>
        
          <div className="container pt-150 pb-150">
            <div className="section-content">
              <div className="row">
                <div className="col-md-6">
                  <CMSWrapper {...contentEntity}>
                  <img src={contentEntity.featureImage && contentEntity.featureImage.size({
                    width: 500,
                    height: 445,
                    focus: 'face',
                    fit: 'fill',
                  })} alt={contentEntity.featureImage && contentEntity.featureImage.title} />
                  </CMSWrapper>
                </div>              
                <div className="col-md-6">
                  {contentEntity && contentEntity && contentEntity.widgets.map(item => (
                        <ContentfulElement key={item.id} {...item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </CMSWrapper>
      </ImageEditor>
    </section> 
  );
}

export default element;